import '../scss/index.scss';
import './lozad';
import './aos';
import './header';
import './swiper';
import './dropdown';
import './lowermv';

const headerHeight = document.querySelector('.header').offsetHeight;
let urlHash = location.hash;
if (urlHash) {
  setTimeout(() => {
    let target = document.querySelector(urlHash);
    let position = target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
    window.scrollTo(0, position);
  }, 1500);
}

window.addEventListener("scroll", function () {
  const cvList = document.querySelector(".c-cvList");
  const scroll = window.pageYOffset;
  if (scroll > 100) {
    cvList.classList.add('is-cvOn');
    // elm.style.opacity = "1";
    // elm.style.zIndex = "1";
    // console.log(scroll);
  } else {
    cvList.classList.remove('is-cvOn');
    // elm.style.opacity = "0";
    // elm.style.zIndex = "-1";
    // console.log(scroll);
  }
});