// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock.es6';

// const header = document.querySelector('.header');
// const hamburger = document.querySelector('.header__hamburger');
// const nav = document.querySelector('.header__nav');

// let isMenuOpened = false;

// window.addEventListener('scroll', () => {
//   if (scrollY > window.innerHeight) {
//     header.classList.add('filled');
//   } else {
//     header.classList.remove('filled');
//   }
// });

// function openMenu() {
//   header.classList.add('menu-opened');
//   hamburger.classList.add('active');
//   disableBodyScroll(nav);
//   isMenuOpened = true;
// }

// function closeMenu() {
//   header.classList.remove('menu-opened');
//   hamburger.classList.remove('active');
//   enableBodyScroll(nav);
//   isMenuOpened = false;
// }

// hamburger.addEventListener('click', () => isMenuOpened ? closeMenu() : openMenu());

// SP nav
document.addEventListener("DOMContentLoaded", function() {
  const navButton = document.querySelector('.js-nav-bt');
  const navContent = document.querySelector('.js-nav-content');
  navButton.addEventListener('click', function() {
    if (navContent.classList.contains('is-navOn')) {
      navContent.classList.remove('is-navOn');
      this.classList.remove('is-navOn');
    } else {
      navContent.classList.add('is-navOn');
      this.classList.add('is-navOn');
    }
  });
  document.querySelectorAll('.js-nav-bt.is-navOn, .js-nav-logo.is-navOn, .js-nav-link').forEach(function(element) {
    element.addEventListener('click', function() {
      document.querySelectorAll(".js-nav-content.is-navOn, .js-nav-bt.is-navOn").forEach(function(target) {
        target.classList.remove('is-navOn');
      });
    });
  });
});

// if (window.matchMedia('(max-width: 1024px)').matches) {
//   //pullDown
//   $(function () {
//     $('.header__navSub').hide();
//     $('.js-pullDown').on('click', function () {
//       $(this).next().slideToggle();
//       $(this).toggleClass('is-on');
//     });
//   });
// }

// 画面幅が1024px以下の場合に実行
if (window.matchMedia('(max-width: 1024px)').matches) {
  // DOMContentLoaded イベントが発生したら実行
  document.addEventListener('DOMContentLoaded', function() {
    // すべての .header__navSub 要素を非表示にする
    const navSubs = document.querySelectorAll('.header__navSub');
    navSubs.forEach(navSub => {
      navSub.style.display = 'none';
    });
    // すべての .js-pullDown 要素にクリックイベントリスナーを追加
    const pullDowns = document.querySelectorAll('.js-pullDown');
    pullDowns.forEach(pullDown => {
      pullDown.addEventListener('click', function(e) {
        e.preventDefault();
        // 次の要素（.header__navSub）の表示/非表示を切り替える
        const nextElement = this.nextElementSibling;
        nextElement.style.display = nextElement.style.display === 'none' ? 'block' : 'none';
        // 自身のクラスに is-on をトグルする
        this.classList.toggle('is-on');
      });
    });
  });
}

