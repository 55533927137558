import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';

if (document.querySelector('.homeVisual__slider .swiper')) {
  new Swiper('.homeVisual__slider .swiper', {
    modules: [Autoplay, EffectFade],
    allowTouchMove: false,
    disableOnInteraction: false,
    effect: 'fade',
    speed: 1000,
    loop: true,
    autoplay: {
      delay: 3000
    }
  });
}
