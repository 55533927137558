function resizeLowerMv(){
  if(window.matchMedia('(min-width: 768px)').matches) {
    const widthControl = document.querySelectorAll('.js-width-control');
    widthControl.forEach((widthControl, index) => {
      const outerwidth = window.outerWidth;
      const innerWidth = 920;
      const imgWidth = ((outerwidth - innerWidth) / 2) + innerWidth;
      widthControl.style.width = imgWidth + 'px';
    });
    const positionControl = document.querySelectorAll('.js-position-control');
    positionControl.forEach((positionControl, index) => {
      const outerwidth = window.outerWidth;
      const innerWidth = 920;
      const positionAdjust = 116;
      const leftPosition = ((outerwidth - innerWidth) / 2) - positionAdjust;
      positionControl.style.left = leftPosition + 'px';
    });
  }
}

resizeLowerMv();
window.onresize = resizeLowerMv;
